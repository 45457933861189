/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations('/').then(registrations => {
      registrations.map(reg => reg.unregister())
    })
  }
}

exports.onRouteUpdate = () => {
  setTimeout(() => {
    loadScripts()
  }, 1000)
}

const loadScripts = () => {
  window.mr = window.mr || {}

  mr = (function(mr, $, window, document) {
    'use strict'

    mr = mr || {}

    var components = {
      documentReady: [],
      documentReadyDeferred: [],
      windowLoad: [],
      windowLoadDeferred: []
    }

    mr.status = { documentReadyRan: false, windowLoadPending: false }

    $(document).ready(documentReady)
    $(window).on('load', windowLoad)

    function documentReady(context) {
      context = typeof context === typeof undefined ? $ : context
      components.documentReady
        .concat(components.documentReadyDeferred)
        .forEach(function(component) {
          component(context)
        })
      mr.status.documentReadyRan = true
      if (mr.status.windowLoadPending) {
        windowLoad(mr.setContext())
      }
    }

    function windowLoad(context) {
      if (mr.status.documentReadyRan) {
        mr.status.windowLoadPending = false
        context = typeof context === 'object' ? $ : context
        components.windowLoad
          .concat(components.windowLoadDeferred)
          .forEach(function(component) {
            component(context)
          })
      } else {
        mr.status.windowLoadPending = true
      }
    }

    mr.setContext = function(contextSelector) {
      var context = $
      if (typeof contextSelector !== typeof undefined) {
        return function(selector) {
          return $(contextSelector).find(selector)
        }
      }
      return context
    }

    mr.components = components
    mr.documentReady = documentReady
    mr.windowLoad = windowLoad

    return mr
  })(window.mr, jQuery, window, document)

  // Accordions
  mr = (function(mr, $, window, document) {
    'use strict'

    mr.accordions = mr.accordions || {}

    mr.accordions.documentReady = function($) {
      $('.accordion__title').on('click', function() {
        mr.accordions.activatePanel($(this))
      })

      $('.accordion').each(function() {
        var accordion = $(this)
        var minHeight = accordion.outerHeight(true)
        accordion.css('min-height', minHeight)
      })

      if (window.location.hash !== '' && window.location.hash !== '#') {
        if ($('.accordion li' + $(this).attr('href')).length) {
          mr.accordions.activatePanelById(window.location.hash, true)
        }
      }

      jQuery(document).on('click', 'a[href^="#"]:not(a[href="#"])', function() {
        if (
          $('.accordion > li > .accordion__title' + $(this).attr('href')).length
        ) {
          mr.accordions.activatePanelById($(this).attr('href'), true)
        }
      })
    }

    mr.accordions.activatePanel = function(panel, forceOpen) {
      var $panel = $(panel),
        accordion = $panel.closest('.accordion'),
        li = $panel.closest('li'),
        openEvent = document.createEvent('Event'),
        closeEvent = document.createEvent('Event')

      openEvent.initEvent('panelOpened.accordions.mr', true, true)
      closeEvent.initEvent('panelClosed.accordions.mr', true, true)

      if (li.hasClass('active')) {
        if (forceOpen !== true) {
          li.removeClass('active')
          $panel
            .trigger('panelClosed.accordions.mr')
            .get(0)
            .dispatchEvent(closeEvent)
        }
      } else {
        if (accordion.hasClass('accordion--oneopen')) {
          var wasActive = accordion.find('li.active')
          if (wasActive.length) {
            wasActive.removeClass('active')
            wasActive
              .trigger('panelClosed.accordions.mr')
              .get(0)
              .dispatchEvent(closeEvent)
          }
          li.addClass('active')
          li.trigger('panelOpened.accordions.mr')
            .get(0)
            .dispatchEvent(openEvent)
        } else {
          if (!li.is('.active')) {
            li.trigger('panelOpened.accordions.mr')
              .get(0)
              .dispatchEvent(openEvent)
          }
          li.addClass('active')
        }
      }
    }

    mr.accordions.activatePanelById = function(id, forceOpen) {
      var panel

      if (id !== '' && id !== '#') {
        panel = $('.accordion > li > .accordion__title#' + id.replace('#', ''))
        if (panel.length) {
          $('html, body')
            .stop(true)
            .animate(
              {
                scrollTop: panel.offset().top - 50
              },
              1200
            )

          mr.accordions.activatePanel(panel, forceOpen)
        }
      }
    }

    mr.components.documentReady.push(mr.accordions.documentReady)
    return mr
  })(mr, jQuery, window, document)

  // Dropdowns
  mr = (function(mr, $, window, document) {
    'use strict'

    mr.dropdowns = mr.dropdowns || {}

    mr.dropdowns.done = false

    mr.dropdowns.documentReady = function($) {
      var rtl = false

      if ($('html[dir="rtl"]').length) {
        rtl = true
      }

      if (!mr.dropdowns.done) {
        jQuery(document).on(
          'click',
          'body:not(.dropdowns--hover) .dropdown:not(.dropdown--hover), body.dropdowns--hover .dropdown.dropdown--click',
          function(event) {
            var dropdown = jQuery(this)
            if (
              jQuery(event.target).is('.dropdown--active > .dropdown__trigger')
            ) {
              dropdown
                .siblings()
                .removeClass('dropdown--active')
                .find('.dropdown')
                .removeClass('dropdown--active')
              dropdown.toggleClass('dropdown--active')
            } else {
              $('.dropdown--active').removeClass('dropdown--active')
              dropdown.addClass('dropdown--active')
            }
          }
        )
        jQuery(document).on(
          'click touchstart',
          'body:not(.dropdowns--hover)',
          function(event) {
            if (
              !jQuery(event.target).is(
                '[class*="dropdown"], [class*="dropdown"] *'
              )
            ) {
              $('.dropdown--active').removeClass('dropdown--active')
            }
          }
        )
        jQuery('body.dropdowns--hover .dropdown').on('click', function(event) {
          event.stopPropagation()
          var hoverDropdown = jQuery(this)
          hoverDropdown.toggleClass('dropdown--active')
        })

        // Append a container to the body for measuring purposes
        jQuery('body').append(
          '<div class="container containerMeasure" style="opacity:0;pointer-events:none;"></div>'
        )

        // Menu dropdown positioning
        if (rtl === false) {
          mr.dropdowns.repositionDropdowns($)
          jQuery(window).on('resize', function() {
            mr.dropdowns.repositionDropdowns($)
          })
        } else {
          mr.dropdowns.repositionDropdownsRtl($)
          jQuery(window).on('resize', function() {
            mr.dropdowns.repositionDropdownsRtl($)
          })
        }

        mr.dropdowns.done = true
      }
    }

    mr.dropdowns.repositionDropdowns = function($) {
      $('.dropdown__container').each(function() {
        var container, containerOffset, masterOffset, menuItem, content

        jQuery(this).css('left', '')
        container = jQuery(this)
        containerOffset = container.offset().left
        masterOffset = jQuery('.containerMeasure').offset().left
        menuItem = container.closest('.dropdown').offset().left
        content = null

        container.css('left', -containerOffset + masterOffset)

        if (container.find('.dropdown__content:not([class*="md-12"])').length) {
          content = container.find('.dropdown__content')
          content.css('left', menuItem - masterOffset)
        }
      })
      $('.dropdown__content').each(function() {
        var dropdown, offset, width, offsetRight, winWidth, leftCorrect

        dropdown = jQuery(this)
        offset = dropdown.offset().left
        width = dropdown.outerWidth(true)
        offsetRight = offset + width
        winWidth = jQuery(window).outerWidth(true)
        leftCorrect = jQuery('.containerMeasure').outerWidth() - width

        if (offsetRight > winWidth) {
          dropdown.css('left', leftCorrect)
        }
      })
    }

    mr.dropdowns.repositionDropdownsRtl = function($) {
      var windowWidth = jQuery(window).width()

      $('.dropdown__container').each(function() {
        var container, containerOffset, masterOffset, menuItem, content

        jQuery(this).css('left', '')

        container = jQuery(this)
        containerOffset =
          windowWidth - (container.offset().left + container.outerWidth(true))
        masterOffset = jQuery('.containerMeasure').offset().left
        menuItem =
          windowWidth -
          (container.closest('.dropdown').offset().left +
            container.closest('.dropdown').outerWidth(true))
        content = null

        container.css('right', -containerOffset + masterOffset)

        if (container.find('.dropdown__content:not([class*="md-12"])').length) {
          content = container.find('.dropdown__content')
          content.css('right', menuItem - masterOffset)
        }
      })
      $('.dropdown__content').each(function() {
        var dropdown, offset, width, offsetRight, winWidth, rightCorrect

        dropdown = jQuery(this)
        offset =
          windowWidth - (dropdown.offset().left + dropdown.outerWidth(true))
        width = dropdown.outerWidth(true)
        offsetRight = offset + width
        winWidth = jQuery(window).outerWidth(true)
        rightCorrect = jQuery('.containerMeasure').outerWidth() - width

        if (offsetRight > winWidth) {
          dropdown.css('right', rightCorrect)
        }
      })
    }

    mr.components.documentReady.push(mr.dropdowns.documentReady)
    return mr
  })(mr, jQuery, window, document)

  // Flickity
  mr = (function(mr, $, window, document) {
    'use strict'
    mr.sliders = mr.sliders || {}

    mr.sliders.documentReady = function($) {
      $('.slider').each(function(index) {
        var slider = $(this)
        var sliderInitializer = slider.find('ul.slides')
        sliderInitializer.find('>li').addClass('slide')
        var childnum = sliderInitializer.find('li').length

        var themeDefaults = {
          cellSelector: '.slide',
          cellAlign: 'left',
          wrapAround: true,
          pageDots: false,
          prevNextButtons: false,
          autoPlay: true,
          draggable: childnum < 2 ? false : true,
          imagesLoaded: true,
          accessibility: true,
          rightToLeft: false,
          initialIndex: 0,
          freeScroll: false
        }

        // Attribute Overrides - options that are overridden by data attributes on the slider element
        var ao = {}
        ao.pageDots =
          slider.attr('data-paging') === 'true' &&
          sliderInitializer.find('li').length > 1
            ? true
            : undefined
        ao.prevNextButtons =
          slider.attr('data-arrows') === 'true' ? true : undefined
        ao.draggable =
          slider.attr('data-draggable') === 'false' ? false : undefined
        ao.autoPlay =
          slider.attr('data-autoplay') === 'false'
            ? false
            : slider.attr('data-timing')
            ? parseInt(slider.attr('data-timing'), 10)
            : undefined
        ao.accessibility =
          slider.attr('data-accessibility') === 'false' ? false : undefined
        ao.rightToLeft = slider.attr('data-rtl') === 'true' ? true : undefined
        ao.initialIndex = slider.attr('data-initial')
          ? parseInt(slider.attr('data-initial'), 10)
          : undefined
        ao.freeScroll =
          slider.attr('data-freescroll') === 'true' ? true : undefined

        // Set data attribute to inidicate the number of children in the slider
        slider.attr('data-children', childnum)

        $(this).data(
          'sliderOptions',
          jQuery.extend({}, themeDefaults, mr.sliders.options, ao)
        )

        $(sliderInitializer).flickity($(this).data('sliderOptions'))

        $(sliderInitializer).on('scroll.flickity', function(event, progress) {
          if (slider.find('.is-selected').hasClass('controls--dark')) {
            slider.addClass('controls--dark')
          } else {
            slider.removeClass('controls--dark')
          }
        })
      })

      if (mr.parallax && mr.parallax.update) {
        mr.parallax.update()
      }
    }

    mr.components.documentReadyDeferred.push(mr.sliders.documentReady)
    return mr
  })(mr, jQuery, window, document)
}
